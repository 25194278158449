<template>
  <section class="mb-8">
    <v-row>
      <v-col cols="12" sm="12" md="3">
        <p class="text-h6 font-weight-bold">Contactos</p>
        <v-list dense class="list--custom">
          <v-list-item-group>
            <v-list-item
              v-for="contact in listaDeContactos"
              :key="contact.id"
              :id="contact.id"
              @click="verDetalleContacto(contact)"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="`${contact?.nombre}`"
                  class="text-subtitle-1"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col
        v-if="Object.keys(detalleContacto).length > 0"
        cols="12"
        sm="12"
        md="9"
      >
        <v-list dense>
          <!-- Tipo de documento -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1"
                >Tipo de documento:</v-list-item-title
              >
              <v-list-item-subtitle>{{
                detalleContacto.tipo_documento
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- Numero de documento -->

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1"
                >Número de documento:</v-list-item-title
              >
              <v-list-item-subtitle>{{
                detalleContacto.numero_documento
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- Nombre -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1">Nombre:</v-list-item-title>
              <v-list-item-subtitle>{{
                detalleContacto.nombre
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- Correo electrónico -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1"
                >Correo electrónico:</v-list-item-title
              >
              <v-list-item-subtitle>{{
                detalleContacto.correo_electronico
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- Tipo de contacto -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1"
                >Tipo de contacto:</v-list-item-title
              >
              <v-list-item-subtitle>{{
                detalleContacto.tipo_contacto
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- Listado de teléfonos -->
          <v-list-item
            v-for="telefono in detalleContacto.rutas_comunicacion"
            :key="telefono.id"
          >
            <v-list-item-content>
              <v-list-item-title class="subtitle-1"
                >{{ telefono.titulo }}:</v-list-item-title
              >
              <v-list-item-subtitle>{{
                telefono.contacto
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- Dirección -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1"
                >Dirección:</v-list-item-title
              >
              <v-list-item-subtitle>{{
                detalleContacto.direccion
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- Cotizaciones -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1"
                >¿Enviará cotizaciones a UCP?
              </v-list-item-title>
              <v-list-item-subtitle>{{
                detalleContacto.cotizacion_uaci ? "Sí" : "No"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1">OBS:</v-list-item-title>
              <v-list-item-subtitle> </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-data-table
            :headers="headersObs"
            :items="detalleContacto.obs"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.obs`]="{ item }">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle
                    >{{ item.codigo }} - {{ item.nombre }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-data-table>
        </v-list>
        <div class="d-flex justify-end">
          <ObservarProveedorComponent />
        </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import ObservarProveedorComponent from "./ObservarProveedorComponent.vue";
export default {
  name: "ContactoVerificacionComponent",
  components: {
    ObservarProveedorComponent,
  },
  data: () => ({
    listaDeContactos: [],
    detalleContacto: {},
    document_types: [],
    contacts_type: [],
    headersObs: [{ text: "OBS", align: "start", value: "obs" }],
  }),
  computed: {},
  methods: {
    async obtenerContactos() {
      const response = await this.services.Proveedores.getProviderContacts({
        pagination: false,
        id_proveedor: this.$route.params.id_proveedor,
      });
      if (response.status === 200) {
        this.listaDeContactos = response?.data;
      }
    },
    async verDetalleContacto(contacto) {
      // Obtenemos el detalle del contacto
      const response = await this.services.Proveedores.getProviderContact(
        contacto.id
      );

      if (response.status === 200) {
        this.detalleContacto = response.data;
      }
    },
  },
  created() {
    this.obtenerContactos();
  },
};
</script>
<style scoped>
.list--custom {
  background-color: transparent;
}

.list--custom .v-list-item {
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 1px solid var(--v-secondary-lighten1) !important;
  border-radius: 5px !important;
}
</style>
