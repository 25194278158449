<template>
  <section>
    <v-row>
      <v-col cols="12" sm="12" md="3">
        <p class="text-h6 font-weight-bold">OBS</p>
        <v-list dense class="list--custom">
          <v-list-group v-for="obs in listadoOBS" :key="obs.id" :id="obs.id">
            <template v-slot:activator>
              <v-list-item-title
                v-text="`${obs.titulo}`"
                class="text-subtitle-1"
              ></v-list-item-title>
            </template>

            <v-list-item
              v-for="(presentation, i) in obs.presentaciones"
              :key="i"
              @click="getCurrentPresentation(presentation.id)"
            >
              <v-list-item-title
                v-text="presentation.nombre"
              ></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>
      <v-col cols="12" sm="12" md="9">
        <v-row v-if="Object.keys(productoSeleccionado).length > 0">
          <v-col cols="12">
            <p class="text-h6 font-weight-bold">
              {{ productoSeleccionado.nombre }}
            </p>
          </v-col>
          <v-col cols="12" md="6" xl="6">
            <ProductCarouselComponent :imagenes="obs_imagenes" />
          </v-col>
          <v-col cols="12" md="6" xl="6">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="caracteristica-producto-subtitulo primary--text pt-5"
              >
                <p>{{ productoSeleccionado.obs_descripcion }}</p>
              </v-col>
              <v-col cols="12" class="caracteristica-producto-titulo pt-5">
                <p class="text-caption">OBS:</p>
              </v-col>
              <v-col
                cols="12"
                class="caracteristica-producto-subtitulo primary--text"
              >
                <p>
                  {{ productoSeleccionado.codigo }} -
                  {{ productoSeleccionado.obs }}
                </p>
              </v-col>
              <v-col cols="12" class="caracteristica-producto-titulo pt-5">
                <p class="text-caption">Unidad de venta:</p>
              </v-col>
              <v-col
                cols="12"
                class="caracteristica-producto-subtitulo primary--text"
              >
                <p>{{ productoSeleccionado.nombre_presentacion }}</p>
              </v-col>
              <v-col cols="12" class="caracteristica-producto-titulo pt-5">
                <p class="text-caption">Detalle:</p>
              </v-col>
              <v-col
                cols="5"
                class="caracteristica-producto-subtitulo primary--text"
              >
                <p
                  v-for="(detail, index) in productoSeleccionado.etiquetas"
                  :key="index"
                >
                  {{ detail.nombre }}
                </p>
              </v-col>
              <v-col cols="12" class="caracteristica-producto-titulo pt-5">
                <p class="text-caption">Precio:</p>
              </v-col>
              <v-col
                cols="12"
                class="caracteristica-producto-subtitulo primary--text"
              >
                <p>
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(productoSeleccionado.precio_unitario)
                  }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class=" d-flex justify-center my-6" v-else>
          <p class="text-h6 secondary--text">No se han registrado presentaciones de OBS para este proveedor.</p>
        </div>
        <ObservarProveedorComponent />
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ProductCarouselComponent from "@/components/ProductCarouselComponent.vue";
import ObservarProveedorComponent from "./ObservarProveedorComponent.vue";
export default {
  name: "OBSVerificacionComponent",
  components: {
    ProductCarouselComponent,
    ObservarProveedorComponent,
  },
  data: () => ({
    listadoOBS: [],
    obsSeleccionado: {},
    obs_imagenes: [],
    productoSeleccionado: {},
  }),
  computed: {
    ...mapState("proveedores", ["provider"]),
  },
  methods: {
    async obtenerOBS() {
      const response = await this.services.Proveedores.getObsPresentaciones(
        this.provider.id
      );

      if (response.status === 200) {
        this.listadoOBS = [];
        for (const {
          id,

          titulo,
          descripcion,
          producto_presentacion,
        } of response.data) {
          const nuevoElemento = {
            id: id,
            titulo: titulo,
            descripcion: descripcion,
            presentaciones: producto_presentacion,
          };
          this.listadoOBS.push(nuevoElemento);
        }
      }
    },
    getPresentationImages() {
      const obs_foto_ids = this.productoSeleccionado.obs_fotos;
      let promises = [];
      let images = [];
      this.obs_imagenes = [];
      for (let i = 0; i < obs_foto_ids.length; i++) {
        promises.push(
          this.services.AgreementMarco.getImagenesPresentacion(
            obs_foto_ids[i].id
          ).then((response) => {
            let files = response?.data;
            const reader = new FileReader();
            reader.onload = (files) => {
              images.push({
                id: i + 1,
                url: files.target.result,
              });
            };
            reader.readAsDataURL(files);
          })
        );
      }
      Promise.all(promises).then(() => {
        setTimeout(() => {
          this.obs_imagenes = images;
        }, 200);
      });
    },
    async getCurrentPresentation(id) {
      const response =
        await this.services.Proveedores.getProviderOBSPresentationDetail(id);

      if (response.status == 200) {
        this.productoSeleccionado = response.data;
        this.getPresentationImages();
      }
    },
  },
  created() {
    this.obtenerOBS();
  },
};
</script>
<style scoped>
.list--custom {
  background-color: transparent;
}

.list--custom .v-list-group {
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 1px solid var(--v-secondary-lighten1) !important;
  border-radius: 5px !important;
}
</style>
