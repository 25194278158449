<template>
  <section>
    <!-- Titulo de la vista -->
    <v-row>
      <v-col class="text-h6 secondary--text"> Accionistas</v-col>
    </v-row>

    <!-- Tabla accionistas -->
    <v-row class="my-4">
      <v-col cols="12">
        <data-table-component
          v-models:select="perPage"
          v-models:pagina="page"
          :headers="headers"
          :items="shareholders"
          @paginar="paginate"
          :total_registros="totalRows"
        >
          <template v-slot:[`item.type`]="{ item }">
            {{ item.documentos[0].tipo_documento ?? "" }}
          </template>

          <template v-slot:[`item.doc`]="{ item }">
            {{ item.documentos[0].numero_documento ?? "" }}
          </template>
          <template v-slot:[`item.percent`]="{ item }">
            {{ item.porcentaje_participacion }} %
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.primer_nombre?.toUpperCase() }}
            {{ item.segundo_nombre?.toUpperCase() }}
            {{ item.tercer_nombre?.toUpperCase() }}
            {{ item.primer_apellido?.toUpperCase() }}
            {{ item.segundo_apellido?.toUpperCase() }}
            {{ item.apellido_casada?.toUpperCase() }}
          </template>
        </data-table-component>
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <ObservarProveedorComponent />
    </div>
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import ObservarProveedorComponent from "./ObservarProveedorComponent.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "AccionistasVerificacionComponent",
  components: {
    DataTableComponent,
    ObservarProveedorComponent,
  },
  data: () => ({
    headers: [
      {
        text: "Documento",
        align: "center",
        value: "type",
      },
      { text: "Número de documento", align: "center", value: "doc" },
      { text: "Nombre", align: "center", value: "name" },
      {
        text: "Participación(%)",
        align: "center",
        value: "percent",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState("shareholders", [
      "shareholders",
      "page",
      "perPage",
      "totalRows",
    ]),
  },
  methods: {
    ...mapActions("shareholders", ["getShareholders", "paginate"]),
  },
  async created() {
    await this.getShareholders({
      id_proveedor: this.$route.params?.id_proveedor,
    });
  },
};
</script>
<style lang=""></style>
