<template>
  <section class="mb-8">
    <v-row>
      <v-col cols="12" sm="12" md="3">
        <p class="text-h6 font-weight-bold">Documentos</p>
        <v-list dense class="list--custom">
          <v-list-item-group v-model="doc_seleccionado">
            <v-list-item
              v-for="doc in docs"
              :key="doc.id"
              :id="doc.id"
              @click="setDocument(doc)"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="`${doc?.TipoDocumento?.nombre}`"
                  class="text-subtitle-1"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="12" sm="12" md="9">
        <div class="d-flex justify-center" v-if="loading_doc">
          <v-progress-circular indeterminate color="primary" />
        </div>
        <embed
          width="100%"
          height="100%"
          :src="doc_seleccionado + '#page=1%zoom=300'"
          v-if="doc_seleccionado"
        />
        <div v-if="doc_seleccionado" class="d-flex justify-end">
          <ObservarProveedorComponent />
        </div>
      </v-col>
    </v-row>

  </section>
</template>
<script>
import { mapState } from "vuex";
import ObservarProveedorComponent from "./ObservarProveedorComponent.vue";
export default {
  name: "DocumentacionVerificacionComponent",
  data: () => ({
    doc_seleccionado: null,
    loading_doc: false,
  }),
  components: {
    ObservarProveedorComponent,
  },
  computed: {
    ...mapState("proveedores", ["provider"]),
    docs() {
      if (this.provider && this.provider.documentos.length > 0) {
        return this.provider.documentos;
      } else return [];
    },
  },
  methods: {
    async setDocument(doc) {
      this.loading_doc = true;
      if(!doc.documento) {
        this.temporalAlert({
            show: true,
            type: "error",
            message: "La documentación solicitada no ha sido adjuntada.",
          });
          return 
        }
      const response = await this.services.Proveedores.getDocumentoProveedor({
        documento: doc.documento,
      });

      if (response.status == 200) {
        let b64 = Buffer.from(response.data).toString("base64");
        this.doc_seleccionado = `data:application/pdf;base64,${b64}`;
      }
      this.loading_doc = false;
    },
  },
};
</script>
<style scoped>
.list--custom {
  background-color: transparent;
}

.list--custom .v-list-item {
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 1px solid var(--v-secondary-lighten1) !important;
  border-radius: 5px !important;
}
</style>
