<template>
    <v-col cols="12" md="2" sm="4" lg="2">
      <v-row justify="center">
        <div class="customItem">
          <v-col cols="12" class="d-flex justify-center">
            <v-icon
              color="primary"
              size="75px"
              class="btns"
              @click="$router.push({ path: route })"
            >
              {{ icon }}
            </v-icon>
          </v-col>
          <v-col cols="12" class="text-center"> {{ label }} </v-col>
        </div>
      </v-row>
    </v-col>
  </template>
  
  <script>
  export default {
    name: 'moduloProveedorComponent.vue',
    props: {
      icon: {
        typeof: String,
      },
      route: {
        typeof: String,
      },
      label: {
        typeof: String,
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .customItem{
       width:150px !important;
  }
  .customItem:hover {
    background: #313945;
    color: white !important;
    border-radius: 4px;
    .v-icon {
      color: white !important;
    }
  }
  </style>