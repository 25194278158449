<template>
  <v-dialog v-model="showModal" max-width="85vw" @click:outside="closeModal">
    <v-card>
      <v-card-title>{{ haveRole('ROLE_SUPERVISOR') ? '' : 'RUPES /' }} {{ provider.nombre }} / Reseñas</v-card-title>
      <v-card-text>
        <v-row class="ma-5">
          <v-col cols="12" md="4">
            <p class="mb-0">Ponderación</p>
            <v-rating
              v-model="ponderacionReseña"
              half-increments
              size="35"
              background-color="secondary"
              color="secondary"
            ></v-rating>
            <p
              v-if="ponderacionError1.length > 0"
              class="red--text ml-4"
              style="font-size: 12px"
            >
              {{ ponderacionError1[0] }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-1 mb-0">
          <v-col cols="12" sm="12" md="7">
            <v-textarea
              label="Reseña del proveedor(opcional)"
              outlined
              v-model="evaluacion"
            ></v-textarea>
          </v-col>
          <v-col cols="3" sm="3" md="3">
            <v-btn
              class="my-7 ml-4"
              color="secondary"
              dark
              @click="guardarEvaluacion()"
              >Enviar reseña</v-btn
            >
          </v-col>
        </v-row>

        <p class="text-h5 secondary--text">Otras reseñas</p>
        <v-list v-if="provider.id">
          <v-list-item class="mb-3" v-for="reseña in reseñas" :key="reseña.id">
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-rating
                  half-increments
                  background-color="secondary"
                  color="secondary"
                  :value="Number(reseña.ponderacion)"
                  size="35"
                ></v-rating>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                >{{ reseña.institucion }}
                {{
                  reseña.correlativo_contrato
                    ? "- " + reseña.correlativo_contrato
                    : ""
                }}</v-list-item-subtitle
              >
              <v-list-item-subtitle>{{
                moment(reseña.created_at).fromNow()
              }}</v-list-item-subtitle>
              <v-list-item-title>{{ reseña.evaluacion }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-row class="my-2">
          <v-pagination
            v-model="filters.page"
            :length="Math.ceil(filters.total_rows / filters.per_page)"
            :total="filters.total_rows"
          ></v-pagination>
        </v-row>
        <v-row class="mx-2 my-4">
          <v-btn outlined color="secondary" @click="closeModal">Volver</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import { helpers, required, minValue } from "vuelidate/lib/validators";
import { onMounted } from 'vue';
export default {
  props: {
    showModal: {
      typeof: Boolean,
    },
    idProveedor: {
      typeof: Number,
    },
  },
  data: () => ({
    reseñas: [],
    ponderacionReseña: null,
    evaluacion: null,
    filters: {
      page: 1,
      per_page: 10,
      total_rows: null,
      pagination: "true",
    },
    page: 1,
  }),
  validations: {
    ponderacionReseña: { required },
  },
  computed: {
    ...mapState("proveedores", ["provider"]),
    ponderacionError1() {
      const errors = [];
      if (!this.$v.ponderacionReseña.$dirty) return errors;
      !this.$v.ponderacionReseña.required &&
        errors.push("Ponderación es requerido");
      return errors;
    },
  },
  methods: {
    ...mapActions("proveedores", ["getProvider"]),
    closeModal() {
      this.$emit("close");
    },
    async getEvaluacionesProveedores() {
      const { data, headers } =
        await this.services.ProveedorEvaluacion.getEvaluacionProveedor(
          this.provider.id
        );
      this.reseñas = data;
      this.filters.page = Number(headers.page);
      this.filters.per_page = Number(headers.per_page);
      this.filters.total_rows = Number(headers.total_rows);
    },
    async guardarEvaluacion() {
        if(this.ponderacionReseña === null){
            this.temporalAlert({
                show:true,
                type:"error",
                message:"Debe de elegir una ponderación"
            })
            this.getEvaluacionesProveedores();
        } else {
        let eva = {};
        eva.id_proveedor = this.provider.id;
        eva.id_contrato = null;
        eva.ponderacion = this.ponderacionReseña;
        eva.evaluacion = this.evaluacion;

        const re =
          await this.services.ProveedorEvaluacion.postEvaluacionProveedor(eva);
        if (re.status === 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Evaluación de proveedor creada",
          });
          this.ponderacionReseña = null;
          this.evaluacion = null;
          this.getEvaluacionesProveedores();
        }
        }
        
    },
  },
  async created() {
    this.getEvaluacionesProveedores();
  },
};
</script>

<style></style>
