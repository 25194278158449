<template>
  <section>
    <div class="d-flex justify-end">
      <v-btn @click="showModal = true" color="pendiente">Observar</v-btn>
    </div>
    <v-dialog v-model="showModal" max-width="800" persistent>
      <v-card>
        <v-card-title>
          <p class="text-h6 secondary--text">Observación de proveedor</p>
        </v-card-title>
        <v-card-text>
          <app-rich-text-component
            v-model="observaciones"
          ></app-rich-text-component>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            outlined
            @click="(showModal = false), (observaciones = '')"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="sendObservation()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ObservarProveedorComponent",
  data: () => ({
    showModal: false,
    observaciones: null,
  }),
  validations: {
    observaciones: {
      required,
    },
  },
  computed: {
    ...mapState("proveedores", ["provider"]),
  },
  methods: {
    async sendObservation() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const { status } = await this.services.Proveedores.verificarProveedor(
          this.provider.id,
          {
            id_estado_verificacion: 2,
            observacion: this.observaciones,
          }
        );

        if (status == 200 || status == 204) {
          this.temporalAlert({
            show: true,
            message: "Observación enviada",
            type: "success",
          });
        }
        this.showModal = false;
      } else {
        this.temporalAlert({
          show: true,
          message: "Las observaciones son obligatorias",
          type: "error",
        });
      }
    },
  },
};
</script>
