<template>
  <v-card min-height="500">
      <div class="d-flex pa-4 justify-space-between">
        <p class="text-h4">Verificación de proveedor</p>
        <v-btn @click="$emit('closeModal')" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    <v-card-text>
      <v-stepper v-model="step" flat>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1"> OBS </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            Giro empresarial
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 3" step="3">
            Documentación
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step :complete="step > 3" step="4">
            Accionistas
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step step="5"> Contacto </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <OBSVerificacionComponent />
            <v-btn text @click="$emit('closeModal')"> Cancelar </v-btn>

            <v-btn class="mx-2" color="secondary" @click="step = 2">
              Siguiente
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <GiroEmpresarialVerificacionComponent />

            <v-btn text @click="step = 1"> Anterior </v-btn>

            <v-btn class="mx-2" color="secondary" @click="step = 3">
              Siguiente
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <DocumentacionVerificacionComponent />

            <v-btn text @click="step = 2"> Anterior </v-btn>

            <v-btn class="mx-2" color="secondary" @click="step = 4">
              Siguiente
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="4">
            <AccionistasVerificacionComponent />

            <v-btn text @click="step = 3"> Anterior </v-btn>

            <v-btn class="mx-2" color="secondary" @click="step = 5">
              Siguiente
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="5">
            <ContactoVerificacionComponent />

            <v-btn text @click="step = 4"> Anterior </v-btn>

            <v-btn
              color="success"
              @click="sendVerificacion()"
              class="mx-2"
              dark
            >
              Verificar proveedor
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-dialog v-model="showModalVerificado" max-width="800" persistent>
        <v-card>
          <v-card-text class="py-4">
            <p class="text-h5 secondary--text text-center">
              Proveedor verificado con éxito
            </p>
            <div class="d-flex justify-center">
              <v-icon size="80" color="yellow">mdi-check-decagram</v-icon>
            </div>
            <div class="d-flex justify-center my-4">
              <v-btn dark color="secondary" @click="closeModal()"
                >Continuar</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import OBSVerificacionComponent from "./OBSVerificacionComponent.vue";
import GiroEmpresarialVerificacionComponent from "./GiroEmpresarialVerificacionComponent.vue";
import DocumentacionVerificacionComponent from "./DocumentacionVerificacionComponent.vue";
import AccionistasVerificacionComponent from "./AccionistasVerificacionComponent.vue";
import ContactoVerificacionComponent from "./ContactoVerificacionComponent.vue";

export default {
  name: "MainVerificacionProveedorComponent",
  components: {
    OBSVerificacionComponent,
    GiroEmpresarialVerificacionComponent,
    DocumentacionVerificacionComponent,
    AccionistasVerificacionComponent,
    ContactoVerificacionComponent,
  },
  data: () => ({
    step: 1,
    showModalVerificado: false,
  }),
  computed: {
    ...mapState("proveedores", ["provider"]),
  },
  methods: {
    async sendVerificacion() {
      const { status } = await this.services.Proveedores.verificarProveedor(
        this.provider.id,
        {
          id_estado_verificacion: 1,
        }
      );

      if (status == 200 || status == 204) {
        this.showModalVerificado = true;
      }
    },
    closeModal() {
      location.reload();
    },
  },
};
</script>
