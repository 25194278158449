<template>
    <v-col cols="12" md="2" sm="4" lg="2">
      <v-row justify="center">
        <div class="customItem">
          <v-col cols="12" class="d-flex justify-center">
            <v-icon
              color="primary"
              size="75px"
              class="btns"
              @click="abrirModalReseña()"
            >
              {{ icon }}
            </v-icon>
          </v-col>
          <v-col cols="12" class="text-center"> {{ label }} </v-col>
        </div>
      </v-row>
      <modal-reseña-component 
       :showModal="modal" 
       @close="cerrar()"
      ></modal-reseña-component>
    </v-col>
  </template>
  
  <script>
  import modalReseñaComponent from './modalReseñaComponent.vue';
  export default {
    components: { modalReseñaComponent },
    name: 'reseñaProveedorComponent.vue',
    props: {
      icon: {
        typeof: String,
      },
      label: {
        typeof: String,
      },
      show:{
        typeof: Boolean,
        default: false
      },
    },
    data:() => ({
      modal:false,
      idProveedor:null,
      reseñas:null,
    }),
    methods:{
      abrirModalReseña(){
        this.modal = true
      },
      cerrar(){
        this.modal = false
      },
    },
    mounted(){
      this.modal = this.show
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .customItem{
       width:150px !important;
  }
  .customItem:hover {
    background: #313945;
    color: white !important;
    border-radius: 4px;
    .v-icon {
      color: white !important;
    }
  }
  </style>