<template>
  <section class="mb-8">
    <!-- Titulo de la vista -->
    <v-row>
      <v-col class="text-h6 secondary--text font-weight-bold">
        Giro empresariales</v-col
      >
    </v-row>

    <!-- 'Tabla con información de los giros empresariales '  -->
    <v-row>
      <v-col cols="12">
        <!-- Se utilizara un v-data-table con la paginacion oculta ya que no se necesita paginar -->
        <v-data-table
          :headers="headers"
          :items="giros"
          :hide-default-footer="true"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <div class="d-flex justify-end mt-6">
      <ObservarProveedorComponent />
    </div>
  </section>
</template>
<script>
import ObservarProveedorComponent from "./ObservarProveedorComponent.vue";
export default {
  name: "GiroEmpresarialVerificacionComponent",
  components: {
    ObservarProveedorComponent,
  },
  data: () => ({
    headers: [{ text: "Nombre", align: "start", value: "nombre" }],
    giros: [],
  }),
  methods: {
    async obtenerGiros() {
      const response =
        await this.services.Proveedores.getBusinessActivitiesList({
          id_proveedor: this.$route.params.id_proveedor,
        });
      if (response.status === 200) {
        this.giros = response.data;
      }
    },
  },
  mounted() {
    this.obtenerGiros();
  },
};
</script>
<style lang=""></style>
