<template>
  <section>
    <v-col cols="12" md="12">
      <div class="d-flex justify-space-between">
        <p class="text-h6 secondary--text mb-0">
          <router-link
            v-if="!haveRole('ROLE_SUPERVISOR')"
            class="secondary--text"
            :to="{ name: 'list-proveedores' }"
            style="text-decoration: none"
          >
            RUPES /
          </router-link>
          {{ ` ${provider.nombre}` }}
          <span v-if="!perfilCompleto"
            >({{ progresoPerfil }} % completado)</span
          >
          <v-tooltip v-else-if="provider.id_estado_verificacion === 1" right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" color="yellow accent-4" size="30"
                >mdi-check-decagram</v-icon
              >
            </template>
            <span>Perfil verificado</span>
          </v-tooltip>
          <v-tooltip v-else right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" color="success" size="30"
                >mdi-check-decagram</v-icon
              >
            </template>
            <span>Perfil completo</span>
          </v-tooltip>
          <v-chip class="ml-2" v-if="provider.sancion && provider.sancion.length > 0" color="error">
            <span class="white--text">Proveedor Inhabilitado</span>         
          </v-chip>
          <v-btn
            class="button-extra text-no-style ma-2 v-step-1"
            color="secondary"
            outlined
            v-if="haveRole('ROLE_RUPES_ALERTA_CREATE')"
            @click="modalAddAlert = true"
          >
            Agregar alerta
          </v-btn>
        </p>
        <v-btn
          class="button-extra text-no-style ma-2 v-step-1"
          color="success"
          dark
          v-if="
            provider.id_estado_verificacion !== 1 &&
            !haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO','ROLE_SUPER_AUDITOR', 'ROLE_UFI_TECNICO', 'ROLE_TECNICO_UNIDAD', 'ROLE_AUDITOR_INTERNO_AUIN'])
          "
          @click="verificarModal = true"
        >
          Verificar
        </v-btn>
      </div>
    </v-col>

    <v-row>
      <v-col lg="6" md="6" sm="12" class="d-flex justify-center">
        <!-- Logo del proveedor -->
        <v-row>
          <v-col class="d-flex justify-center">
            <v-img
              v-if="providerLogo"
              max-height="300"
              max-width="300"
              :src="showImage"
            />
            <v-icon v-else color="primary" size="300px">
              mdi-image-outline
            </v-icon>
          </v-col>
        </v-row>
      </v-col>

      <!-- Información del proveedor -->

      <v-col lg="6" md="6" sm="12" class="px-10">
        <v-row>
          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Nombre del proveedor</p>
            <p class="text-info">{{ provider.nombre }}</p>
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Nombre comercial</p>
            <p v-if="!editFields" class="text-info">
              {{ provider.nombre_comercial }}
            </p>
            <v-text-field
              dense
              v-else
              v-model="editableFields.nombre_comercial"
              @input="$v.editableFields.nombre_comercial.$touch()"
              :error-messages="NombreComercialErrors"
            />
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Tipo de persona</p>
            <p class="text-info">{{ provider.tipo_contribuyente }}</p>
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Género</p>
            <p class="text-info">{{ provider.genero }}</p>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <p class="text-title">
              {{ getDocumentName(provider.documentos) }}
            </p>
            <p class="text-info">
              {{ getDocumentNumber(provider.documentos) }}
            </p>
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Sitio web</p>
            <p v-if="!editFields" class="text-info">
              {{ provider.sitio_web ? provider.sitio_web : "No posee" }}
            </p>
            <v-text-field
              @input="$v.editableFields.sitio_web.$touch()"
              :error-messages="SitioWebErrors"
              dense
              v-else
              v-model="editableFields.sitio_web"
            />
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Clasificación de la empresa</p>
            <p class="text-info">{{ provider.clasificacion_empresa }}</p>
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <P class="text-title">Teléfono</P>
            <p v-if="!editFields" class="text-info">
              {{ `${provider.area + " " + provider.telefono}` }}
            </p>
            <v-row v-else>
              <v-col cols="3">
                <v-text-field readonly dense v-model="editableFields.area" />
              </v-col>
              <v-col cols="9">
                <v-text-field
                  @input="$v.editableFields.telefono.$touch()"
                  :error-messages="phoneErrors"
                  dense
                  v-mask="mask"
                  v-model="editableFields.telefono"
                  :maxLength="20"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="provider.id_identificacion_tributaria === 3" class="espace" cols="12">
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <div class="mb-4">
                  <p class="text-title" style="font-size: 0.9em">¿Está constituida como una sociedad por acciones simplificada (SAS)?</p>
                  <v-switch 
                    v-model="editableFields.sas"
                    hide-details
                    :disabled="!editFields"
                    color="secondary"
                    class="mt-0"
                    :label="editableFields.sas ? 'Si' : 'No'"
                    :truthy-value="true"
                    :false-value="false"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Dirección</p>
            <p v-if="!editFields" class="text-info">
              {{ getAddress(provider.direccion) }}
            </p>
            <template v-else>
              <div class="mt-6 mb-4">
                <v-autocomplete
                  :error-messages="paisErrors"
                  v-model="editableFields.id_pais"
                  dense
                  label="País"
                  :items="paises"
                  item-value="id"
                  item-text="nombre"
                  class="mr-4"
                  @change="clearAddressFields()"
                />
              </div>
              <div class="mb-6">
                <v-autocomplete
                  v-if="editableFields.id_pais == 68"
                  v-model="editableFields.id_departamento"
                  dense
                  :items="departamentos"
                  label="Departamento"
                  class="mr-4"
                  item-value="id"
                  item-text="nombre"
                  @blur="$v.editableFields.id_departamento.$touch()"
                  :error-messages="departamentoErrors"
                  @change="getMunicipios()"
                />
              </div>
              <div class="mb-6">
                <v-autocomplete
                  v-if="editableFields.id_pais == 68"
                  v-model="editableFields.id_municipio"
                  dense
                  :items="municipios"
                  label="Municipio"
                  class="mr-4"
                  item-value="id"
                  @blur="$v.editableFields.id_municipio.$touch()"
                  :error-messages="municipioErrors"
                  item-text="nombre"
                />
              </div>
              <div class="">
                <v-textarea
                  v-model="editableFields.direccion"
                  dense
                  rows="2"
                  label="Dirección"
                  class="mr-4"
                  @input="$v.editableFields.direccion.$touch()"
                  :error-messages="addressErrors"
                />
              </div>
            </template>
          </v-col>
          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Correo electrónico</p>
            <p v-if="!editFields" class="text-info">{{ provider.email }}</p>
            <v-text-field
              v-else
              @input="$v.editableFields.email.$touch()"
              :error-messages="emailErrors"
              dense
              v-model="editableFields.email"
              :maxLength="50"
            />
          </v-col>

          <v-col
            class="espace"
            cols="12"
            v-if="haveRoles(['ROLE_PROVEEDOR', 'ROLE_EDITAR_INFO_PROVEEDOR'])"
          >
            <span class="grayMinsal--text">
              Clic aquí para editar la información
            </span>
            <v-btn
              @click="editFields = true"
              icon
              v-if="!editFields"
              class="mx-3"
              color="secondary"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <template v-else>
              <v-btn
                @click="UpdateProvider()"
                icon
                class="mx-1"
                color="secondary"
              >
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
              <v-btn @click="editFields = false" icon color="secondary">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Opciones del menu de usuario -->

    <v-row class="d-flex justify-center mt-16" v-if="provider.id">
      <ModuloProveedor
        icon="mdi-archive-plus-outline"
        label="OBS"
        route="obs-proveedor"
        :params="{ id_proveedor: provider.id_usuario }"
      />
      <ModuloProveedor
        icon="mdi-briefcase-check-outline"
        label="Giro empresarial"
        route="giro-empresarial-proveedor"
        :params="{ id_proveedor: provider.id_usuario }"
      />
      <ModuloProveedor
        icon="mdi-text-box-plus-outline"
        label="Documentación"
        route="documentacion-proveedor"
        :params="{ id_proveedor: provider.id_usuario }"
      />
      <ModuloProveedor
        icon="mdi-phone-outline"
        label="Contactos"
        route="contactos-proveedor"
        :params="{ id_proveedor: provider.id_usuario }"
      />

      <PerfilItem
        v-if="
          !(
            [1].includes(
              provider?.identificacion_tributaria?.id_tipo_contribuyente
            ) && [1, 2].includes(provider?.id_clasificacion_empresa)
          )
        "
        icon="mdi-account-group-outline"
        label="Accionistas"
        route="accionistas"
        :params="{ idProveedor: provider.id_usuario }"
      />
      <template
        v-if="
          haveRoles([
            'ROLE_TECNICO_DIRECCION_COMPRAS',
            'ROLE_DIRECCION_COMPRAS',
            'ROLE_CALLCENTER',
            'ROLE_VISTA_PROCESOS_ODC_RUPES',
          ]) && !haveRole('ROLE_PROVEEDOR')
        "
      >
        <ModuloProveedor
          icon="mdi-file-tree-outline"
          label="Procesos"
          route="procesos-proveedor"
          :params="{ id_proveedor: provider.id_usuario }"
        />
        <ModuloProveedor
          icon="mdi-file-sign"
          label="Contratos"
          route="contratos-proveedor"
          :params="{ id_proveedor: provider.id_usuario }"
        />
        <moduloSancionComponent
          icon="mdi-alert-outline"
          label="Sanciones"
          :route="`/proveedor/${provider.id_usuario}/sancion`"
        ></moduloSancionComponent>
      </template>
      <ReseñaProveedorComponent
        icon="mdi-comment-outline"
        label="Reseña"
        :show="modalReseña"
      />
    </v-row>
    <v-row class="mt-16">
      <v-col cols="12" sm="4" md="3">
        <v-btn
          outlined
          block
          color="secondary"
          class="text-no-style"
          @click="$router.push({ name: 'list-proveedores' })"
        >
          Volver
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="modalAddAlert" max-width="600" persistent>
      <v-card>
        <div class="headerIcon d-flex justify-start pr-4 pt-4">
          <v-icon class="secondary--text" @click="modalAddAlert = false">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <div>
            <p class="text-h6 secondary--text">Agregar alerta</p>
            <AppRichTextComponent
              v-model="text_alert"
              placeholder="Escriba una alerta para el proveedor aquí"
              @input="touchTextAlert"
            />
            <v-fade-transition>
              <span
                v-show="textAlertErrors"
                class="ml-1 error--text"
                v-text="textAlertErrors"
              />
            </v-fade-transition>
          </div>
          <!-- acciones -->
          <v-row class="d-flex justify-center mx-sm-4 mb-6 mt-6">
            <v-btn
              class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
              color="secondary"
              outlined
              @click="modalAddAlert = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
              color="secondary"
              @click="addAlertProveedor"
            >
              Registrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--modal verificación proveedor -->
    <v-dialog v-model="verificarModal" max-width="1500" persistent>
      <MainVerificacionProveedorComponent
        @closeModal="verificarModal = false"
      />
    </v-dialog>
  </section>
</template>

<script>
import PerfilItem from "../PerfilProveedor/components/PerfilItemComponent.vue";
import ModuloProveedor from "./components/moduloProveedorComponent.vue";
import ReseñaProveedorComponent from "./components/reseñaProveedorComponent.vue";
import moduloSancionComponent from "./components/moduloSancionComponent.vue";
import { mapActions, mapMutations, mapState } from "vuex";

import MainVerificacionProveedorComponent from "./components/verificacionProveedor/MainVerificacionProveedorComponent.vue";
import {
  helpers,
  requiredIf,
  maxLength,
  required,
  email,
} from "vuelidate/lib/validators";

const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ÿ0-9-_.#\u00f1\u00d1 ]*$/);
const address = helpers.regex("address", /^[0-9A-Za-zñÑáéíóúÁÉÍÓÚ,.#_\-' ]*$/);
function phoneValid(value) {
  if (value) {
    if (this.editableFields.area == "+503") {
      if (
        value.charAt(0) == "2" ||
        value.charAt(0) == "6" ||
        value.charAt(0) == "7"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      const regexAlphaNum = /^[0-9-]+$/;
      return regexAlphaNum.test(value);
    }
  } else {
    return true;
  }
}
const url = helpers.regex(
  "url",
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
);

export default {
  name: "proveedorView",
  components: {
    ModuloProveedor,
    PerfilItem,
    ReseñaProveedorComponent,
    MainVerificacionProveedorComponent,
    moduloSancionComponent,
  },
  validations: {
    editableFields: {
      nombre_comercial: { required, maxLength: maxLength(100), alpha },
      sitio_web: { url },
      direccion: { required, address, maxLength: maxLength(100) },
      id_pais: { required },
      telefono: { phoneValid },
      id_departamento: {
        required: requiredIf(function () {
          return this.editableFields?.id_pais == 68 ? true : false;
        }),
      },
      id_municipio: {
        required: requiredIf(function () {
          return this.editableFields?.id_pais == 68 ? true : false;
        }),
      },
      email: { required, email },
    },
  },
  data: () => ({
    logo: null,
    proveedor: [],
    progresoPerfil: null,
    perfilCompleto: null,
    editFields: false,
    editableFields: {
      nombre_comercial: null,
      sitio_web: null,
      telefono: null,
      area: null,
      id_pais: null,
      id_departamento: null,
      id_municipio: null,
      direccion: null,
      email: null,
      sas: false,
    },
    paises: [],
    departamentos: [],
    municipios: [],
    mask: null,
    modalAddAlert: false,
    text_alert: "<p></p>",
    textAlertErrors: null,
    modalReseña: false,
    verificarModal: false,
  }),
  computed: {
    ...mapState("proveedores", ["provider", "providerLogo"]),
    showImage() {
      if (this.providerLogo) return this.providerLogo;
      return this.logo;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.editableFields.direccion.$dirty) return errors;
      !this.$v.editableFields.direccion.required &&
        errors.push("La dirección es requerida");
      !this.$v.editableFields.direccion.address &&
        errors.push(
          "La dirección es inválida, caracteres especiales no permitidos"
        );
      !this.$v.editableFields.direccion.maxLength &&
        errors.push("La dirección no debe exceder los 100 caracteres");
      return errors;
    },
    NombreComercialErrors() {
      const errors = [];
      if (!this.$v.editableFields.nombre_comercial.$dirty) return errors;
      !this.$v.editableFields.nombre_comercial.alpha &&
        errors.push("Nombre comercial solo admite caracteres alfanuméricos");
      !this.$v.editableFields.nombre_comercial.maxLength &&
        errors.push("El nombre comercial no debe exceder los 100 caracteres");
      !this.$v.editableFields.nombre_comercial.required &&
        errors.push("El nombre comercial es requerido");

      return errors;
    },
    SitioWebErrors() {
      const errors = [];
      if (!this.$v.editableFields.sitio_web.$dirty) return errors;
      !this.$v.editableFields.sitio_web.url &&
        errors.push("El sitio web es inválido");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.editableFields.telefono.$dirty) return errors;
      !this.$v.editableFields.telefono.phoneValid &&
        errors.push("Escriba un número de teléfono válido");
      return errors;
    },
    paisErrors() {
      const errors = [];
      if (!this.$v.editableFields.id_pais.$dirty) return errors;
      !this.$v.editableFields.id_pais.required &&
        errors.push("El campo país es obligatorio");
      return errors;
    },
    departamentoErrors() {
      const errors = [];
      if (!this.$v.editableFields.id_departamento.$dirty) return errors;
      !this.$v.editableFields.id_departamento.required &&
        errors.push("El campo departamento es obligatorio");
      return errors;
    },
    municipioErrors() {
      const errors = [];
      if (!this.$v.editableFields.id_municipio.$dirty) return errors;
      !this.$v.editableFields.id_municipio.required &&
        errors.push("El campo municipio es obligatorio");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editableFields.email.$dirty) return errors;
      !this.$v.editableFields.email.required &&
        errors.push("El campo correo electrónico es obligatorio");
      !this.$v.editableFields.email.email &&
        errors.push("El correo electrónico es inválido");
      return errors;
    },
  },
  methods: {
    ...mapMutations("proveedores", ["setProviderInfo"]),
    touchTextAlert() {
      // el text_alert es texto enriquecido, verificar q no este vacio
      if (this.text_alert == "<p></p>") {
        this.textAlertErrors = "Por favor ingrese un valor en el campo";
      } else {
        this.textAlertErrors = null;
      }
    },
    ...mapActions("proveedores", ["getProvider", "getProviderLogo"]),
    getDocumentName(data) {
      if (data !== undefined && data.length > 0) {
        return data[0]?.TipoDocumento?.nombre;
      }
      return "";
    },
    getDocumentNumber(data) {
      if (data !== undefined && data.length > 0) {
        return data[0]?.numero_documento;
      }
      return "";
    },
    getAddress(data) {
      let address = `${data?.pais}${
        data?.departamento ? ", " + data?.departamento : ""
      }${
        data?.municipio ? ", " + this.getMunicipioName(data?.municipio) : ""
      }, ${data?.detalle_direccion}`;
      return address;
    },
    getMunicipioName(data) {
      let name = data?.split(" ");
      name?.pop();
      return name?.join(" ");
    },
    getCode(pais) {
      const code = this.paises.find((item) => item.id === pais);
      return code?.codigo_area;
    },
    clearAddressFields() {
      if (this.editableFields.id_pais !== 68) {
        this.editableFields.id_departamento = null;
        this.editableFields.id_municipio = null;
        this.mask = null;
      } else {
        this.mask = "####-####";
        this.getDepartamentos();
      }
      this.editableFields.area = this.getCode(this.editableFields.id_pais);
    },
    fillFields() {
      Object.keys(this.editableFields).forEach((key) => {
        this.editableFields[key] = this.provider[key];
        if (
          key == "id_pais" ||
          key == "id_departamento" ||
          key == "id_municipio"
        ) {
          this.editableFields[key] = this.provider.direccion[key];
        }
        if (key == "direccion")
          this.editableFields[key] = this.provider.direccion.detalle_direccion;
      });
      if (this.editableFields.id_pais == 68) {
        this.mask = "####-####";
      }
    },

    async getProfilePercentage() {
      if (this.$route.params.id_proveedor) {
        let response = await this.services.Proveedores.getProviderPercentage(
          Number(this.$route.params.id_proveedor)
        );

        if (response.status == 200) {
          this.progresoPerfil = response?.data.progreso;
          this.perfilCompleto = response?.data.perfil_completo;
        }
      }
    },
    async getPaises() {
      const response = await this.services.Paises.getListaPaises();
      this.paises = response?.data;
    },
    async getDepartamentos() {
      this.editableFields.area = this.getCode(this.editableFields.id_pais);
      if (this.editableFields.id_pais != null) {
        const response = await this.services.Departamentos.getDepartamentosList(
          { id_pais: this.editableFields.id_pais }
        );
        this.departamentos = response?.data;
      }
    },
    async getMunicipios() {
      if (this.editableFields.id_departamento != null) {
        const response = await this.services.Municipios.getMunicipiosList({
          id_departamento: this.editableFields.id_departamento,
        });
        this.municipios = response?.data;
      }
    },
    async UpdateProvider() {
      this.$v.editableFields.$touch();

      if (!this.$v.editableFields.$invalid) {

        const snapshot = {
          nombre_comercial: this.provider?.nombre_comercial,
          sitio_web: this.provider?.sitio_web,
          telefono: this.provider?.telefono,
          area: this.provider?.area,
          direccion: this.provider?.direccion?.detalle_direccion,
          id_pais: this.provider?.direccion?.id_pais,
          id_departamento: this.provider?.direccion?.id_departamento,
          id_municipio: this.provider?.direccion?.id_municipio,
          email: this.provider?.email,
        };

        const fields = Object.keys(this.editableFields).reduce((acc, key) => {
          if (snapshot[key] !== this.editableFields[key]) {
            acc[key] = this.editableFields[key];
          }
          return acc;
        }, {});

        if (Object.keys(fields).length === 0) {
          this.temporalAlert({
            show: true,
            type: "info",
            message: "No se han realizado cambios",
          });

          return;
        } else {
          let response = await this.services.Proveedores.updateProviderInfo({
            ...fields,
            id_proveedor: Number(this.$route.params.id_proveedor),
          });

          if (response.status == 200) {
            this.editFields = false;
            await this.getProvider(Number(this.$route.params.id_proveedor));
            this.temporalAlert({
              show: true,
              message: "La información ha sido actualizada",
              type: "success",
            });
          }
        }

      }
    },
    async addAlertProveedor() {
      this.touchTextAlert();
      if (this.textAlertErrors) {
        return;
      }

      const resp = await this.services.Proveedores.addAlertProveedor({
        id_proveedor: Number(this.provider?.id),
        alerta: this.text_alert,
      });

      if (resp.status == 200) {
        this.modalAddAlert = false;
        this.temporalAlert({
          show: true,
          message: resp?.data?.message,
          type: "success",
        });
      }

    },
  },

  watch: {
    provider: {
      handler(value) {
        if (!value) return;

        this.fillFields();
      },
      immediate: true,
      deep: true,
    },
    modalAddAlert(value) {
      if (!value) {
        this.text_alert = "<p></p>";
        this.textAlertErrors = null;
      }
    },
  },

  async created() {
    this.proveedor.url_image = "";
    await this.getProvider(Number(this.$route.params.id_proveedor));
    this.getProviderLogo(this.provider.id);
    this.fillFields();
    await this.getPaises();
    await this.getDepartamentos();
    await this.getMunicipios();
    await this.getProfilePercentage();
  },

  beforeDestroy() {
    this.setProviderInfo({});
  },
};
</script>

<style scoped>
.image-input {
  max-width: 500px;
}

.text-title {
  font-size: 12px;
  margin: 0;
}

.text-info {
  font-size: 16px;
  margin: 0;
}

.espace {
  padding-bottom: 10px;
}

.input-space {
  padding-top: 50px;
}
</style>
